import Link from 'next/link';
import { connect } from 'react-redux';
import Image from 'next/image';

const mapStateToProps = (state) => ({
  serviceSegments: state.user.serviceSegments,
});

const mapDispatchToProps = (dispatch) => ({});

function OurServices(props) {
  const { serviceSegments } = props;

  function renderSegments() {
    return serviceSegments.map((item, key) => (
      <div key={key} className="col-lg-2 col-md-4 col-6 px-md-3 px-2 py-md-3 py-1">
        <Link
          href={{
            pathname: `/service/${item.title}`,
          }}
        >
          <a className="text-decoration-none label-color-2">
            <div className="cursor-pointer services d-flex align-items-center justify-content-center">
              <div className="text-center">
                <img src={item.image} width={70} height={70} alt="" />
                <p className="fs-22">{item.name}</p>
              </div>
            </div>
          </a>
        </Link>
      </div>
    ));
  }

  return (
    <>
      <div className="row">
        <div className="col-lg-12 our-services-section">
          <h5 className="fs-50 fw-600 text-center mb-3">Our Services</h5>
          <div className="row justify-content-center">{serviceSegments && renderSegments()}</div>
        </div>
      </div>
    </>
  );
}
export default connect(mapStateToProps, mapDispatchToProps)(OurServices);
